import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
// import Root from './Root';

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const SignInSignUp = React.lazy(() => import("../pages/auth/SignInSignUp"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// auth2
const Login2 = React.lazy(() => import("../pages/auth2/Login2"));
const Logout2 = React.lazy(() => import("../pages/auth2/Logout2"));
const Register2 = React.lazy(() => import("../pages/auth2/Register2"));
const Confirm2 = React.lazy(() => import("../pages/auth2/Confirm2"));
const ForgetPassword2 = React.lazy(
  () => import("../pages/auth2/ForgetPassword2")
);
const LockScreen2 = React.lazy(() => import("../pages/auth2/LockScreen2"));
const SignInSignUp2 = React.lazy(() => import("../pages/auth2/SignInSignUp2"));

// landing
const Landing = React.lazy(() => import("../pages/landing/"));

// dashboard
const Dashboard1 = React.lazy(() => import("../pages/dashboard/Dashboard1/"));
const Dashboard2 = React.lazy(() => import("../pages/dashboard/Dashboard2/"));
const Dashboard3 = React.lazy(() => import("../pages/dashboard/Dashboard3/"));
const Dashboard4 = React.lazy(() => import("../pages/dashboard/Dashboard4/"));

// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));
const Projects = React.lazy(() => import("../pages/apps/Projects/"));
const ProjectDetail = React.lazy(
  () => import("../pages/apps/Projects/Detail/")
);
const ProjectForm = React.lazy(
  () => import("../pages/apps/Projects/ProjectForm")
);
// - chat
const ChatApp = React.lazy(() => import("../pages/apps/Chat/"));
// - ecommece pages
const EcommerceDashboard = React.lazy(
  () => import("../pages/apps/Ecommerce/Dashboard/")
);
const EcommerceProducts = React.lazy(
  () => import("../pages/apps/Ecommerce/Products")
);
const ProductDetails = React.lazy(
  () => import("../pages/apps/Ecommerce/ProductDetails")
);
const ProductEdit = React.lazy(
  () => import("../pages/apps/Ecommerce/ProductEdit")
);
const Customers = React.lazy(() => import("../pages/apps/Ecommerce/Customers"));
const Orders = React.lazy(() => import("../pages/apps/Ecommerce/Orders"));
const OrderDetails = React.lazy(
  () => import("../pages/apps/Ecommerce/OrderDetails")
);
const Sellers = React.lazy(() => import("../pages/apps/Ecommerce/Sellers"));
const Cart = React.lazy(() => import("../pages/apps/Ecommerce/Cart"));
const Checkout = React.lazy(() => import("../pages/apps/Ecommerce/Checkout"));
// - crm pages
const CRMDashboard = React.lazy(() => import("../pages/apps/CRM/Dashboard/"));
const CRMContacts = React.lazy(() => import("../pages/apps/CRM/Contacts/"));
const Opportunities = React.lazy(
  () => import("../pages/apps/CRM/Opportunities/")
);
const CRMLeads = React.lazy(() => import("../pages/apps/CRM/Leads/"));
const CRMCustomers = React.lazy(() => import("../pages/apps/CRM/Customers/"));
// - email
const Inbox = React.lazy(() => import("../pages/apps/Email/Inbox"));
const EmailDetail = React.lazy(() => import("../pages/apps/Email/Detail"));
const EmailCompose = React.lazy(() => import("../pages/apps/Email/Compose"));
// - social
const SocialFeed = React.lazy(() => import("../pages/apps/SocialFeed/"));
// - companies
const Companies = React.lazy(() => import("../pages/apps/Companies/"));
// - tasks
const TaskList = React.lazy(() => import("../pages/apps/Tasks/List/"));
const TaskDetails = React.lazy(() => import("../pages/apps/Tasks/Details"));
const Kanban = React.lazy(() => import("../pages/apps/Tasks/Board/"));
// -contacts
const ContactsList = React.lazy(() => import("../pages/apps/Contacts/List/"));
const ContactsProfile = React.lazy(
  () => import("../pages/apps/Contacts/Profile/")
);
// -tickets
const TicketsList = React.lazy(() => import("../pages/apps/Tickets/List/"));
const TicketsDetails = React.lazy(
  () => import("../pages/apps/Tickets/Details/")
);
// - file
const FileManager = React.lazy(() => import("../pages/apps/FileManager"));

// extra pages
const Starter = React.lazy(() => import("../pages/other/Starter"));
const Timeline = React.lazy(() => import("../pages/other/Timeline"));
const Sitemap = React.lazy(() => import("../pages/other/Sitemap/"));
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error404Two = React.lazy(() => import("../pages/error/Error404Two"));
const Error404Alt = React.lazy(() => import("../pages/error/Error404Alt"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const Error500Two = React.lazy(() => import("../pages/error/Error500Two"));
// - other
const Invoice = React.lazy(() => import("../pages/other/Invoice"));
const FAQ = React.lazy(() => import("../pages/other/FAQ"));
const SearchResults = React.lazy(() => import("../pages/other/SearchResults/"));
const Upcoming = React.lazy(() => import("../pages/other/Upcoming"));
const Pricing = React.lazy(() => import("../pages/other/Pricing"));
const Gallery = React.lazy(() => import("../pages/other/Gallery/"));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));

// uikit
const Buttons = React.lazy(() => import("../pages/uikit/Buttons"));
const Avatars = React.lazy(() => import("../pages/uikit/Avatars"));
const Cards = React.lazy(() => import("../pages/uikit/Cards"));
const Portlets = React.lazy(() => import("../pages/uikit/Portlets"));
const TabsAccordions = React.lazy(
  () => import("../pages/uikit/TabsAccordions")
);
const Progress = React.lazy(() => import("../pages/uikit/Progress"));
const Modals = React.lazy(() => import("../pages/uikit/Modals"));
const Notifications = React.lazy(() => import("../pages/uikit/Notifications"));
const Offcanvases = React.lazy(() => import("../pages/uikit/Offcanvas"));
const Placeholders = React.lazy(() => import("../pages/uikit/Placeholders"));
const Spinners = React.lazy(() => import("../pages/uikit/Spinners"));
const Images = React.lazy(() => import("../pages/uikit/Images"));
const Carousels = React.lazy(() => import("../pages/uikit/Carousel"));
const ListGroups = React.lazy(() => import("../pages/uikit/ListGroups"));
const EmbedVideo = React.lazy(() => import("../pages/uikit/EmbedVideo"));
const Dropdowns = React.lazy(() => import("../pages/uikit/Dropdowns"));
const Ribbons = React.lazy(() => import("../pages/uikit/Ribbons"));
const TooltipsPopovers = React.lazy(
  () => import("../pages/uikit/TooltipsPopovers")
);
const GeneralUI = React.lazy(() => import("../pages/uikit/GeneralUI"));
const Typography = React.lazy(() => import("../pages/uikit/Typography"));
const Grid = React.lazy(() => import("../pages/uikit/Grid"));
const NestableList = React.lazy(() => import("../pages/uikit/NestableList"));
const DragDrop = React.lazy(() => import("../pages/uikit/DragDrop"));
const RangeSliders = React.lazy(() => import("../pages/uikit/RangeSliders"));
const Animation = React.lazy(() => import("../pages/uikit/Animation"));
const TourPage = React.lazy(() => import("../pages/uikit/TourPage"));
const SweetAlerts = React.lazy(() => import("../pages/uikit/SweetAlerts"));
const LoadingButtons = React.lazy(
  () => import("../pages/uikit/LoadingButtons")
);

// widgets
const Widgets = React.lazy(() => import("../pages/uikit/Widgets"));

// icons
const TwoToneIcons = React.lazy(() => import("../pages/icons/TwoToneIcons/"));
const FeatherIcons = React.lazy(() => import("../pages/icons/FeatherIcons/"));
const Dripicons = React.lazy(() => import("../pages/icons/Dripicons/"));
const MDIIcons = React.lazy(() => import("../pages/icons/MDIIcons/"));
const FontAwesomeIcons = React.lazy(
  () => import("../pages/icons/FontAwesomeIcons/")
);
const ThemifyIcons = React.lazy(() => import("../pages/icons/ThemifyIcons/"));
const SimpleLineIcons = React.lazy(
  () => import("../pages/icons/SimpleLineIcons/")
);
const WeatherIcons = React.lazy(() => import("../pages/icons/WeatherIcons/"));

// forms
const BasicForms = React.lazy(() => import("../pages/forms/Basic"));
const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"));
const FormValidation = React.lazy(() => import("../pages/forms/Validation"));
const FormWizard = React.lazy(() => import("../pages/forms/Wizard"));
const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"));
const Editors = React.lazy(() => import("../pages/forms/Editors"));

// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"));
const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"));

// charts
const ApexChart = React.lazy(() => import("../pages/charts/Apex"));
const ChartJs = React.lazy(() => import("../pages/charts/ChartJs"));

// maps
const GoogleMaps = React.lazy(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = React.lazy(() => import("../pages/maps/VectorMaps"));

// - ondemand pages
// const OnDemandDashboard = React.lazy(() => import("../pages/apps/NKB/Dashboard"));
const NKBHome = React.lazy(() => import("pages/apps/NKB/Home"));
const NKBGeneralDashboard = React.lazy(() => import("pages/apps/NKB/GeneralDashboard"));
const NKBDashboard = React.lazy(() => import("pages/apps/NKB/Dashboard"));
const NKBOrder = React.lazy(() => import("pages/apps/NKB/Order"));
const NKBExpenses = React.lazy(() => import("pages/apps/NKB/Expenses"));
const NKBMDataBrand = React.lazy(() => import("pages/apps/NKB/MasterData/Brand"));
const NKBMDataProduct = React.lazy(() => import("pages/apps/NKB/MasterData/Product"));
const NKBMDataClient = React.lazy(() => import("pages/apps/NKB/MasterData/Client"));
const NKBMDataStaff = React.lazy(() => import("pages/apps/NKB/MasterData/Staff"));
const NKBMDataPartner = React.lazy(() => import("pages/apps/NKB/MasterData/Partner"));
const NKBMDataVendor = React.lazy(() => import("pages/apps/NKB/MasterData/Vendor"));
const NKBMDataTypeOfExpenses = React.lazy(() => import("pages/apps/NKB/MasterData/TypeOfExpenses"));
const NKBAccessControlRole = React.lazy(() => import("pages/apps/NKB/AccessControl/Role"));
const NKBAccessControlPermission = React.lazy(() => import("pages/apps/NKB/AccessControl/Permission"));
const NKBAccessControlUser = React.lazy(() => import("pages/apps/NKB/AccessControl/User"));
const NKBDeposit = React.lazy(() => import("pages/apps/NKB/Deposit"));
const NKBBalance = React.lazy(() => import("pages/apps/NKB/Balance"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// root routes
// const rootRoute: RoutesProps = {
//     path: '/',
//     exact: true,
//     element: () => <Root />,
//     route: Route,
// };

// const orderAppRoutes = {
//   path: "/orders",
//   name: "Orders",
//   route: PrivateRoute,
//   roles: ["Super Admin","Admin"],
//   icon: "users",
//   element: <NKBOrder />,
// };

// const expensesAppRoutes = {
//   path: "/expenses",
//   name: "Expenses",
//   route: PrivateRoute,
//   roles: ["Super Admin","Admin"],
//   icon: "users",
//   element: <NKBExpenses />,
// };

const homeRoutes = {
  path: "/",
  name: "Home",
  route: PrivateRoute,
  roles: ["Super Admin","Admin"],
  icon: "home",
  element: <NKBHome />,
};

const generalDashboardRoutes = {
  path: "/general-dashboard",
  name: "Dashboard",
  route: PrivateRoute,
  roles: ["Super Admin","Admin"],
  icon: "dashboard",
  element: <NKBGeneralDashboard />,
};

const appsRoutes = {
  path: "/",
  name: "Apps",
  route: PrivateRoute,
  roles: ["Super Admin","Admin"],
  icon: "users",
  children: [
    // {
    //   path: "/dashboard/kamboja-ondemand",
    //   name: "Dashboard",
    //   element: <NKBDashboard />,
    //   route: PrivateRoute,
    //   roles: ["Super Admin","Admin"],
    // },
    // {
    //   path: "/dashboard/kamboja-membership",
    //   name: "Dashboard",
    //   element: <NKBDashboard />,
    //   route: PrivateRoute,
    //   roles: ["Super Admin","Admin"],
    // },
    {
      path: "/dashboard/:brand_slug",
      name: "Dashboard",
      element: <NKBDashboard />,
      route: PrivateRoute,
      roles: ["Super Admin","Admin"],
    },
    {
      path: "/orders/:brand_slug",
      name: "Orders",
      element: <NKBOrder />,
      route: PrivateRoute,
      roles: ["Super Admin","Admin"],
    },
    {
      path: "/expenses/:brand_slug",
      name: "Expenses",
      element: <NKBExpenses />,
      route: PrivateRoute,
      roles: ["Super Admin","Admin"],
    },
  ],
};

const masterDataAppRoutes = {
  path: "/mdata",
  name: "Master Data",
  route: PrivateRoute,
  roles: ["Super Admin","Admin"],
  icon: "users",
  children: [
    {
      path: "/mdata/brands",
      name: "Brand",
      element: <NKBMDataBrand />,
      route: PrivateRoute,
      roles: ["Super Admin","Admin"],
    },
    {
      path: "/mdata/products",
      name: "Product/Project",
      element: <NKBMDataProduct />,
      route: PrivateRoute,
      roles: ["Super Admin","Admin"],
    },
    {
      path: "/mdata/clients",
      name: "Client",
      element: <NKBMDataClient />,
      route: PrivateRoute,
      roles: ["Super Admin","Admin"],
    },
    {
      path: "/mdata/staff",
      name: "Staff",
      element: <NKBMDataStaff />,
      route: PrivateRoute,
      roles: ["Super Admin","Admin"],
    },
    {
      path: "/mdata/partners",
      name: "Partner",
      element: <NKBMDataPartner />,
      route: PrivateRoute,
      roles: ["Super Admin","Admin"],
    },
    {
      path: "/mdata/vendors",
      name: "Vendor",
      element: <NKBMDataVendor />,
      route: PrivateRoute,
      roles: ["Super Admin","Admin"],
    },
    {
      path: "/mdata/type-of-expenses",
      name: "Type of Expenses",
      element: <NKBMDataTypeOfExpenses />,
      route: PrivateRoute,
      roles: ["Super Admin","Admin"],
    },
  ],
};

const rolePermissionRoutes = {
  path: "/access-control",
  name: "User Role & Permission",
  route: PrivateRoute,
  roles: ["Super Admin"],
  icon: "users",
  children: [
    {
      path: "/access-control/users",
      name: "User",
      element: <NKBAccessControlUser />,
      route: PrivateRoute,
      roles: ["Super Admin"],
    },
    {
      path: "/access-control/roles",
      name: "Role",
      element: <NKBAccessControlRole />,
      route: PrivateRoute,
      roles: ["Super Admin"],
    },
    {
      path: "/access-control/permissions",
      name: "Permission",
      element: <NKBAccessControlPermission />,
      route: PrivateRoute,
      roles: ["Super Admin"],
    },
  ],
};

const depositRoutes = {
  path: "/deposits",
  name: "Deposit",
  element: <NKBDeposit />,
  route: PrivateRoute,
  roles: ["Super Admin"],
  icon: "database",
  children: [
  ],
};

const balanceRoutes = {
  path: "/mutasi",
  name: "Mutasi",
  element: <NKBBalance />,
  route: PrivateRoute,
  roles: ["Super Admin","Admin"],
  icon: "database",
  children: [
  ],
};

const appRoutes = [
  // NKB
  // orderAppRoutes,
  // expensesAppRoutes,
  homeRoutes,
  generalDashboardRoutes,
  appsRoutes,
  masterDataAppRoutes,
  rolePermissionRoutes,
  depositRoutes,
  balanceRoutes,
];

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/signin-signup",
    name: "SignIn-SignUp",
    element: <SignInSignUp />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
  {
    path: "/auth/login2",
    name: "Login2",
    element: <Login2 />,
    route: Route,
  },
  {
    path: "/auth/logout2",
    name: "Logout2",
    element: <Logout2 />,
    route: Route,
  },
  {
    path: "/auth/register2",
    name: "Register2",
    element: <Register2 />,
    route: Route,
  },
  {
    path: "/auth/confirm2",
    name: "Confirm2",
    element: <Confirm2 />,
    route: Route,
  },
  {
    path: "/auth/forget-password2",
    name: "Forget Password2",
    element: <ForgetPassword2 />,
    route: Route,
  },
  {
    path: "/auth/signin-signup2",
    name: "SignIn-SignUp2",
    element: <SignInSignUp2 />,
    route: Route,
  },
  {
    path: "/auth/lock-screen2",
    name: "Lock Screen2",
    element: <LockScreen2 />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  // dashboardRoutes,
  ...appRoutes,
  // extrapagesRoutes,
  // uiRoutes,
];
const publicRoutes = authRoutes;

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const authProtectedRolesRoutes = authProtectedRoutes.map((item: any) => ({ path: item.path, roles: item.roles, children: item.children || undefined})).filter(item => item?.roles !== undefined);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  authProtectedRolesRoutes,
  publicProtectedFlattenRoutes,
};
