import React from "react";
import ReactDOM from "react-dom/client";

import "./i18n";

import App from "./App";

import { Provider } from "react-redux";
// import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";

// RTK Query
import { store } from "./redux-rtk/store";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  // Redux Saga (OLD)
  // <Provider store={configureStore({})}>
  //   <React.Fragment>
  //     <BrowserRouter basename={process.env.PUBLIC_URL}>
  //       <App />
  //     </BrowserRouter>
  //   </React.Fragment>
  // </Provider>
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
