import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// helpers
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
} from "helpers";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

const AUTH_SESSION_KEY = "nkb_user";
const api = new APICore();

const userData = {
  id: null,
  username: null,
  password: null,
  firstName: null,
  lastName: null,
  role: null,
  token: null,
};

const initialState = {
  user: userData,
  loading: false,
  error: false,
  userSignUp: false,
  userLoggedIn: false,
  passwordReset: false,
  passwordChange: false,
  resetPasswordSuccess: null,
};

interface UserData {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
}

export const loginUser = createAsyncThunk(
  "@@auth/LOGIN_USER",
  async (params: any) => {
    const response = await loginApi(params);
    return response.data;
  }
);

export const authSlice = createSlice({
  name: "@@auth",
  initialState,
  reducers: {
    API_RESPONSE_SUCCESS: (state, action) => {},
    API_RESPONSE_ERROR: (state, action) => {},
    LOGIN_USER: (state, action) => {
      return {
        ...state,
        user: action.payload.data,
        userLoggedIn: true,
        loading: false,
      };
    },
    LOGOUT_USER: (state, action) => {
      api.setLoggedInUser(userData);
      setAuthorization(null);

      return {
        ...state,
        user: userData,
        loading: false,
        userLoggedIn: false,
        userLogout: true,
      };
    },
    SIGNUP_USER: (state, action) => {
      return {
        ...state,
        loading: false,
        userSignUp: true,
      };
    },
    FORGOT_PASSWORD: (state, action) => {
      return {
        ...state,
        resetPasswordSuccess: action.payload.data,
        loading: false,
        passwordReset: true,
      };
    },
    FORGOT_PASSWORD_CHANGE: (state, action) => {},
    RESET: (state, action) => {
      api.setLoggedInUser(null);
      setAuthorization(null);
      return {
        ...state,
        loading: false,
        error: false,
        userSignUp: false,
        userLoggedIn: false,
        passwordReset: false,
        passwordChange: false,
        resetPasswordSuccess: null,
      };
    },
    CHECK: (state, action) => {
      if (state.user.token) {
        api.setLoggedInUser({
          id: state.user.id,
          username: state.user.username,
          password: state.user.password,
          firstName: state.user.firstName,
          lastName: state.user.lastName,
          role: state.user.role,
          token: state.user.token,
        });
      } else {
        api.setLoggedInUser(null);
        setAuthorization(null);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      api.setLoggedInUser(action.payload.result.user);
      setAuthorization(action.payload.result.access_token);
      
      return {
        ...state,
        user: {
          ...action.payload.result.user,
          token: action.payload.result.access_token,
        },
        userLoggedIn: true,
        loading: false,
      };
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      api.setLoggedInUser(null);
      setAuthorization(null);
      return {
        ...state,
        user: userData,
        userLoggedIn: false,
        loading: false,
      };
    });
  },
});

export const {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  LOGIN_USER,
  LOGOUT_USER,
  SIGNUP_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CHANGE,
  RESET,
  CHECK,
} = authSlice.actions;
const { reducer } = authSlice;
export default reducer;

// export const { API_RESPONSE_SUCCESS, API_RESPONSE_ERROR, LOGIN_USER, LOGOUT_USER, SIGNUP_USER, FORGOT_PASSWORD, FORGOT_PASSWORD_CHANGE, RESET } = authSlice.actions

// export default authSlice.reducer
